import classNames from 'classnames';
import Image from 'next/image';
import { FC } from 'react';

import carmaGenericImageLoader from 'src/general/helpers/carmaGenericImageLoader';

import { FlexibleSection } from '../flexibleContent.type';
import styles from './CarmaSponsorships.module.scss';

interface Props extends FlexibleSection {
  sectionData: {
    title: string;
    logos: Array<{ url: string; title: string }>;
    subtext?: string;
  };
  className?: string;
}

export const CarmaSponsorships: FC<Props> = ({ sectionData: { logos, title, subtext }, className }) => {
  return (
    <section className={classNames(styles.root, className)}>
      <div className={styles.contentWrapper}>
        <header className={styles.header}>
          <h5 className={styles.title}>{title}</h5>
        </header>
        <div className={styles.logosWrapper}>
          {logos.map((logo) => (
            <div className={styles.logo} key={logo.title}>
              <Image
                src={logo.url}
                alt={logo.title}
                width={111}
                height={111}
                layout="responsive"
                loader={carmaGenericImageLoader}
              />
            </div>
          ))}
        </div>
        {subtext && <div className={styles.subtext}>{subtext}</div>}
      </div>
    </section>
  );
};
