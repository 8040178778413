import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import styles from './MediaSection.module.scss';

type Props = {
  className?: string;
  title?: ReactNode;
  subTitle?: ReactNode;
  medias: { id: string; media: ReactNode; description?: ReactNode }[];
};

/**
 * Section containing a media (example image or video)
 */
export const MediaSection: FC<Props> = ({ className, title, subTitle, medias }) => (
  <section className={classNames(styles.root, className, 'mediaSection')}>
    {(title || subTitle) && (
      <header className={styles.header}>
        {title && <h2 className="c-fs-h4">{title}</h2>}
        {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
      </header>
    )}
    <div
      className={classNames('media', {
        mediaMultiple: medias.length > 1,
      })}
    >
      {medias.map(({ id, media, description }) => (
        <figure key={id} className={styles.mediaContainer}>
          {media}
          {!!description && <figcaption className={styles.mediaDescription}>{description}</figcaption>}
        </figure>
      ))}
    </div>
  </section>
);
