import { isNil } from 'lodash';
import Link from 'next/link';
import { FC, ReactNode } from 'react';

import { FlexibleSectionPayload } from 'src/data/Contentful/ContentfulGetFlexibleSection';
import { IconEmail } from 'src/general/Icons/IconEmail';
import { IconMessage } from 'src/general/Icons/IconMessage';
import { IconPhone } from 'src/general/Icons/IconPhone';

import styles from './Footer.module.scss';
import classNames from 'classnames';

interface LinkObject {
  label: string;
  href: string;
}

interface Props {
  content: {
    copyright: ReactNode;
    supportTitle: ReactNode;
    supportContent: ReactNode;
    buttonEmailLabel: ReactNode;
    buttonEmailValue: string;
    buttonPhoneLabel: ReactNode;
    buttonPhoneValue: string;
    buttonMessageLabel: ReactNode;
    buttonMessageValue: string;
  };
  items?: {
    label: string;
    children: LinkObject[];
  }[];
  licences?: ReactNode;
  term?: LinkObject;
  policy?: LinkObject;
  awardsSectionData?: FlexibleSectionPayload;
  awardsSection?: ReactNode;
}

export const Footer: FC<Props> = ({ content, items, licences, term, policy, awardsSection }) => {
  const {
    copyright,
    supportTitle,
    supportContent,
    buttonEmailLabel,
    buttonEmailValue,
    buttonPhoneLabel,
    buttonPhoneValue,
    buttonMessageLabel,
    buttonMessageValue,
  } = content;

  const contactItems = [
    {
      id: 1,
      label: buttonEmailLabel,
      subLabel: buttonEmailValue,
      icon: <IconEmail />,
      href: `mailto:${buttonEmailValue}`,
    },
    {
      id: 2,
      label: buttonPhoneLabel,
      subLabel: buttonPhoneValue,
      icon: <IconPhone />,
      href: `tel:${buttonPhoneValue}`,
    },
    {
      id: 3,
      label: buttonMessageLabel,
      subLabel: buttonMessageValue,
      icon: <IconMessage />,
      href: `sms:${buttonMessageValue}`,
    },
  ];

  if (isNil(items)) {
    return null;
  }

  return (
    <footer className={styles.root}>
      <div className={styles.container}>
        {/* Get in touch section */}
        <section className={styles.contact}>
          <header className={styles.contactHeader}>
            <h2 className={classNames('c-fw-bold', 'c-fs-h4', styles.contactTitle)}>{supportTitle}</h2>
          </header>
          <div className={classNames('c-fw-normal', 'c-fs-body1', styles.contactContent)}>{supportContent}</div>
          <footer className={styles.contactFooter}>
            <div className={styles.groupBtnWrapper}>
              {contactItems.map((item) => (
                <Link href={item.href} key={item.id} className={styles.button}>
                  <div className={styles.iconWrapper}>{item.icon}</div>
                  <span className={styles.btnLabelWrapper}>
                    <span className={classNames('c-fw-bold', 'c-fs-h6', styles.btnLabel)}>{item.label}</span>
                    <span className={classNames('c-fw-bold', 'c-fs-body1', `${styles.btnText} ${styles.btnEmText}`)}>
                      {item.subLabel}
                    </span>
                  </span>
                </Link>
              ))}
            </div>
          </footer>
        </section>
        {/* Footer links */}
        <section className={styles.linksContainer}>
          <nav className={styles.links}>
            {items.map((item, indexItem) => (
              <section className={styles.linksItem} key={indexItem}>
                <header className={styles.linksHeader}>
                  <h3
                    data-testid={`FOOTER_HEADER_${indexItem}`}
                    className={classNames('c-fw-ultrabold', 'c-fs-overline', styles.linksTitle)}
                  >
                    {item.label}
                  </h3>
                </header>
                <ul className={styles.linksList}>
                  {item.children.map((link, indexLink) => (
                    <li className={styles.linksListItem} key={indexLink}>
                      <Link href={link.href} className={classNames('c-fw-normal', 'c-fs-h6', styles.linksListLink)}>
                        {link.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </section>
            ))}
          </nav>
        </section>
        {!!awardsSection && <section className={styles.awardsContainer}>{awardsSection}</section>}
        {/* Licence */}
        {!isNil(licences) && (
          <section className={styles.licencesContainer}>
            <div className={classNames('c-fw-normal', 'c-fs-body2', styles.licences)}>{licences}</div>
          </section>
        )}
      </div>
      {/* Legals */}
      {!isNil(copyright) && (
        <section className={styles.legalsContainer}>
          <p className={classNames('c-fw-normal', 'c-fs-body2', styles.legals)}>{copyright}</p>
          {!isNil(term) && !isNil(policy) && (
            <ul className={styles.legalsLinks}>
              {!isNil(term) && (
                <li className={styles.legalsLinkItem}>
                  <Link href={term.href} className={classNames('c-fw-bold', 'c-fs-body2', styles.legalsLink)}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    {term.label}
                  </Link>
                </li>
              )}
              {!isNil(policy) && (
                <li className={styles.legalsLinkItem}>
                  <Link href={policy.href} className={classNames('c-fw-bold', 'c-fs-body2', styles.legalsLink)}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    {policy.label}
                  </Link>
                </li>
              )}
            </ul>
          )}
        </section>
      )}
    </footer>
  );
};
