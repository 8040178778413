'use client';

import classNames from 'classnames';
import { FC, ReactNode, useRef } from 'react';

import { IconCaretLeft } from 'src/general/Icons/IconCaretLeft';
import { IconCaretRight } from 'src/general/Icons/IconCaretRight';

import { ButtonIcon } from '../Button/ButtonIcon';
import { ScrollBox } from '../ScrollBox/ScrollBox';
import styles from './Carousel.module.scss';

type Props = {
  className?: string;
  tiles: { id: string; tile: ReactNode }[];
  /** Disable the shadow on the tiles */
  noShadow?: boolean;
  controls?: {
    prev: (onClick: () => void) => ReactNode;
    next: (onClick: () => void) => ReactNode;
  };
};

export const Carousel: FC<Props> = ({ className, tiles, noShadow, controls }) => {
  const rootRef = useRef<HTMLDivElement>(null);

  const handleNextClick = () => {
    if (rootRef.current) {
      const scrollWidth = getScrollByLength(rootRef.current);
      const carouselTrack = rootRef.current.querySelector('.carouselTrack');

      if (scrollWidth !== null && carouselTrack !== null) {
        carouselTrack.scrollTo({
          left: Math.min(carouselTrack.scrollWidth, carouselTrack.scrollLeft + scrollWidth),
          behavior: 'smooth',
        });
      }
    }
  };

  const handlePrevClick = () => {
    if (rootRef.current) {
      const scrollWidth = getScrollByLength(rootRef.current);
      const carouselTrack = rootRef.current.querySelector('.carouselTrack');

      if (scrollWidth !== null && carouselTrack !== null) {
        carouselTrack.scrollTo({
          left: Math.max(0, carouselTrack.scrollLeft - scrollWidth),
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <div className={classNames(styles.root, 'carousel', className)} ref={rootRef}>
      <ScrollBox className={classNames(styles.track, 'carouselTrack')}>
        {tiles.map(({ id, tile }) => (
          <div className={classNames(styles.tile, 'carouselTile', { [styles.noShadow]: noShadow })} key={id}>
            {tile}
          </div>
        ))}
      </ScrollBox>
      <div className={classNames(styles.controls, 'carouselControls')}>
        {controls ? (
          <>
            {controls.prev(handlePrevClick)}
            {controls.next(handleNextClick)}
          </>
        ) : (
          <>
            <ButtonIcon
              title="Previous slide"
              Icon={IconCaretLeft}
              variant="secondary"
              size="small"
              styling="square"
              className={classNames(styles.prev, 'carouselPrev')}
              onClick={handlePrevClick}
              data-testid="carousel-prev"
            />
            <ButtonIcon
              title="Next slide"
              Icon={IconCaretRight}
              variant="secondary"
              size="small"
              styling="square"
              className={classNames(styles.next, 'carouselNext')}
              onClick={handleNextClick}
              data-testid="carousel-next"
            />
          </>
        )}
      </div>
    </div>
  );
};

const getScrollByLength = (root: HTMLDivElement): number | null => {
  const rootWidth = root.clientWidth;
  // Get the list of tiles in the carousel
  const tiles = root.querySelectorAll('.carouselTile');
  if (tiles.length) {
    const tileWidth = tiles[0].clientWidth;
    // Scroll by at least a tile width or 60% of the track
    const scrollWidth = Math.max(tileWidth, Math.floor(rootWidth / tileWidth) * 0.6 * tileWidth);

    return scrollWidth;
  }

  return null;
};
