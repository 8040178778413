import shallow from 'zustand/shallow';

import { CAR_PURCHASE_STATUS } from 'src/constants';
import { carStatusStore } from 'src/stores/carStatusStore';

const checkCarStatus = (state: CAR_PURCHASE_STATUS) => {
  if (state !== 'delivered' && state !== 'purchased') {
    return state;
  }

  return null;
};

/**
 * takes in sku and current status of a car, checks whether it is in a list of unavailable cars and returns updated state if it exists.
 * @param sku
 * @param currentCarStatus
 * @returns
 */
export const useUpdatedCarStatus = (sku: string, currentCarStatus: CAR_PURCHASE_STATUS) => {
  const carDataState = carStatusStore((state) => state.unavailableCarsData[sku], shallow);

  return checkCarStatus(carDataState) ?? currentCarStatus;
};
