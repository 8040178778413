import classNames from 'classnames';
import Link from 'next/link';
import { FC } from 'react';

import { Image } from 'src/general/components/Image/Image';

import { FlexibleSection } from '../flexibleContent.type';
import styles from './LogoAndContent.module.scss';

interface Props extends FlexibleSection {
  sectionData: {
    title: string;
    content: string;
    link?: {
      label: string;
      href: string;
    };
    images: {
      url: string;
    }[];
  };
}

export const LogoAndContent: FC<Props> = ({ sectionData: { title, content, link, images } }) => {
  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <main className={styles.main}>
          <h2 className={classNames(styles.title, 'c-fw-bold', 'c-fs-h4')}>{title}</h2>
          <span className={classNames('c-fw-normal', 'c-fs-body1', styles.markdown)}>
            {content} {link && <Link href={link.href}>{link.label}</Link>}
          </span>
        </main>
        <div className={styles.imgContainer}>
          {images.map((img) => (
            <div className={styles.image} key={img.url}>
              <Image src={img.url} alt="logo" width={80} height={80} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
