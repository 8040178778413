import classNames from 'classnames';
import Image from 'next/image';
import { FC } from 'react';

import carmaGenericImageLoader from 'src/general/helpers/carmaGenericImageLoader';

import { FlexibleSection } from '../flexibleContent.type';
import styles from './CarmaBusinessPartners.module.scss';

interface Props extends FlexibleSection {
  sectionData: {
    title: string;
    logos: Array<{ url: string; title: string }>;
  };
  className?: string;
}

export const CarmaBusinessPartners: FC<Props> = ({ sectionData: { title, logos }, className }) => {
  return (
    <section className={classNames(styles.root, className)}>
      <header className={styles.header}>
        <h4 className={classNames('c-fw-bold', 'c-fs-body1', styles.title)}>{title}</h4>
      </header>
      <div className={styles.logosWrapper}>
        {logos.map((logo) => (
          <div className={styles.logo} key={logo.title}>
            <Image
              src={logo.url}
              alt={logo.title}
              className={styles.logoImage}
              width={240}
              height={240}
              loader={carmaGenericImageLoader}
            />
          </div>
        ))}
      </div>
    </section>
  );
};
