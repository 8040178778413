import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { FC } from 'react';
import ReactMarkdown from 'react-markdown';

import { FlexibleSection } from '../flexibleContent.type';
import { OptInForm } from './OptInForm';
import styles from './OptInSection.module.scss';

interface Props extends FlexibleSection {
  sectionData: {
    background?: 'purple' | 'blue' | 'white';
    title: string;
    subTitle?: string;
    content: string;
    buttonLabel: string;
  };
}

export const OptInSection: FC<Props> = ({
  sectionData: { background = 'purple', title, subTitle, content, buttonLabel },
}) => {
  return (
    <section className={classNames(styles.root, styles[background])}>
      <h4 className={classNames(styles.title, 'c-fw-bold', 'c-fs-h4')}>{title}</h4>
      {!isEmpty(subTitle) && <h6 className={classNames(styles.subTitle, 'c-fw-normal', 'c-fs-h6')}>{subTitle}</h6>}
      <ReactMarkdown className={classNames(styles.content, 'c-fw-normal', 'c-fs-body1')}>{content}</ReactMarkdown>
      <OptInForm buttonLabel={buttonLabel} />
    </section>
  );
};
