import classNames from 'classnames';
import { FC, ThHTMLAttributes } from 'react';

import { IconCircleCheck } from 'src/general/Icons/IconCircleCheck';
import { IconCircleCrossFilled } from 'src/general/Icons/IconCircleCrossFilled';
import { FlexibleSection } from 'src/hygiene/sections/flexible-content/flexibleContent.type';

import { ParsedTokens, parseTokensRecursive } from '../utils/Token/Token';
import styles from './ComparisonTable.module.scss';

type Icon = 'tick' | 'cross';

interface CellData {
  icon?: Icon;
  text?: string;
}

interface Row {
  label: string;
  left: CellData;
  right: CellData;
}

interface Props extends FlexibleSection {
  sectionData: {
    columnHeaderLeft: string;
    columnHeaderRight: string;
    rows: Row[];
  };
}

export const ComparisonTable: FC<Props> = ({ sectionData }) => {
  // Parse any tokens in text in section data.
  const { columnHeaderLeft, columnHeaderRight, rows } = parseTokensRecursive(sectionData);

  return (
    <section className={styles.root}>
      <table className={styles.table}>
        <thead className={styles.tableHeader}>
          <tr>
            <HeaderCell className={styles.columnHeader} scope="col" />
            <HeaderCell className={classNames(styles.columnLeft, 'c-fw-bold', 'c-fs-h6')} scope="col">
              {columnHeaderLeft}
            </HeaderCell>
            <HeaderCell className={classNames(styles.columnRight, 'c-fw-bold', 'c-fs-h6')} scope="col">
              {columnHeaderRight}
            </HeaderCell>
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {rows.map(({ label, left, right }) => (
            <tr key={label as string}>
              <HeaderCell className={classNames(styles.columnHeader, 'c-fw-bold', 'c-fs-body2')} scope="row">
                {label}
              </HeaderCell>
              <Cell className={styles.columnLeft} {...left} />
              <Cell className={styles.columnRight} {...right} />
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

type HeaderCellProps = ThHTMLAttributes<HTMLTableCellElement>;

/**
 * Wrapper around <th /> element with added styling.
 */
const HeaderCell: FC<HeaderCellProps> = ({ className, children, ...rest }) => (
  <th className={classNames(styles.cell, styles.headerCell, className)} {...rest}>
    {children}
  </th>
);

interface CellProps extends ParsedTokens<CellData> {
  className?: string;
}

/**
 * Wrapper around <td /> element with added styling and icon/text.
 */
const Cell: FC<CellProps> = ({ className, icon, text }) => (
  <td className={classNames(styles.cell, className)}>
    {icon === 'tick' && <IconCircleCheck className={styles.cellIcon} />}
    {icon === 'cross' && <IconCircleCrossFilled className={styles.cellIcon} />}
    {text && <p className={classNames(styles.cellText, 'c-fw-normal', 'c-fs-body2')}>{text}</p>}
  </td>
);
