'use client';

import { isEmpty } from 'lodash';
import { ComponentProps, FC, useEffect, useState } from 'react';
import { Fetcher } from 'swr';
import useSWRImmutable from 'swr/immutable';
import Cookies from 'universal-cookie';

import { COOKIE_RECENTLY_VIEWED } from 'src/constants';
import { fetchVehiclesBySku } from 'src/data/ProductApi/ProductApiListVehicles';
import { productDetailPayloadToBaseCarData } from 'src/data/ProductApi/ProductDetailPayloadToBaseCarData';
import { CarouselSection } from 'src/general/components/Carousel/CarouselSection';
import { sortBySku } from 'src/general/helpers/sortBySku';
import { PlpMarketingTile } from 'src/plp/components/PlpMarketingTile/PlpMarketingTile';
import { ProductCard } from 'src/plp/components/ProductCard/ProductCard';
import { ProductCardLoading } from 'src/plp/components/ProductCard/ProductCardLoading';
import { ProductListDataPayload } from 'src/types/CataloguePage.types';
import { pushToDataLayer } from 'src/utils/pushToDataLayer';

import { FlexibleSection } from '../flexibleContent.type';
import styles from './RecentlyViewedCarousel.module.scss';

const MIN_RECENTLY_VIEWED = 2;

interface Props extends FlexibleSection {
  sectionData: {
    title?: string;
    marketingItems?: ComponentProps<typeof PlpMarketingTile>[];
  };
}

const skusFetcher: Fetcher<ProductListDataPayload, { id: string; skus: string[] }> = ({ skus }) =>
  fetchVehiclesBySku(skus);

export const RecentlyViewedCarousel: FC<Props> = ({ sectionData }) => {
  const [recentlyViewed, setRecentlyViewed] = useState<string[]>([]);
  /**
   * Display condition for recently viewed carousel: at least 2 cars without a marketing tile or at least 1 car with a marketing tile
   */
  const { data, isLoading } = useSWRImmutable(
    (recentlyViewed.length >= MIN_RECENTLY_VIEWED && !sectionData.marketingItems) ||
      (recentlyViewed.length < MIN_RECENTLY_VIEWED && sectionData.marketingItems)
      ? null
      : { id: 'fetchVehiclesBySku', skus: [...recentlyViewed].sort((a, b) => a.localeCompare(b)) },
    skusFetcher,
  );

  useEffect(() => {
    const cookies = new Cookies();
    const recentlyViewedData = cookies.get(COOKIE_RECENTLY_VIEWED);
    if (recentlyViewedData && Array.isArray(recentlyViewedData)) {
      setRecentlyViewed(recentlyViewedData);
    }
  }, []);

  if (recentlyViewed.length < MIN_RECENTLY_VIEWED) {
    return null;
  }

  const tiles = generateTiles(isLoading, recentlyViewed, data?.results, sectionData.marketingItems);

  return (
    <section className={styles.root}>
      {!isEmpty(sectionData.title) && (
        <header className={styles.header}>
          <h2 className="c-fs-h4">{sectionData.title}</h2>
        </header>
      )}
      <CarouselSection tiles={tiles} />
    </section>
  );
};

const generateTiles = (
  isLoading: boolean,
  skus: string[],
  results?: ProductListDataPayload['results'],
  marketingItems?: ComponentProps<typeof PlpMarketingTile>[],
): ComponentProps<typeof CarouselSection>['tiles'] => {
  if (isLoading || !results) {
    return [...Array(4).keys()].map((item) => ({
      id: `${item}`,
      tile: <ProductCardLoading key={`loading-${item}`} />,
    }));
  }

  // Sort the results based on the skus
  const sortedResults = sortBySku(results, skus);

  const tiles = sortedResults.map((product, index) => ({
    id: product.sku,
    tile: (
      <ProductCard
        carData={productDetailPayloadToBaseCarData(product)}
        weeklyRepayment={product.weeklyRepayment}
        defaultRate={product.defaultRate}
        variant="plp"
        noShadow
        onCardClick={() => {
          const event = {
            event: 'carousel_tile_click',
            name: 'recentlyViewed',
            value: 'recentlyViewed',
            index,
            ecommerce: {
              items: [
                {
                  index: 0,
                  item_id: product.sku,
                  item_name: product.title,
                  currency: 'AUD',
                  item_brand: product.make,
                  item_category: product.model,
                  item_category2: product.body,
                  item_category3: product.transmission,
                  item_category4: product.ancapRating,
                  item_category5: product.buildYear,
                  item_variant: product.colour,
                  price: product.price,
                },
              ],
            },
          };
          pushToDataLayer(event);
        }}
      />
    ),
  }));

  if (marketingItems) {
    const marketingTiles = marketingItems.map((tile) => ({
      id: tile.title ?? '',
      tile: <PlpMarketingTile {...tile} variant="carousel" />,
    }));
    tiles.push(...marketingTiles);
  }

  return tiles;
};
