import classNames from 'classnames';
import { FC } from 'react';

import { Image } from 'src/general/components/Image/Image';

import { MediaSection } from '../../MediaSection/MediaSection';
import { FlexibleSection } from '../flexibleContent.type';
import styles from './InlineImage.module.scss';
import { InlineImageJsonLD } from './InlineImageJsonLD';

type ImageProps = {
  description?: string;
  url: string;
};

interface Props extends FlexibleSection {
  sectionData: {
    title?: string;
    subTitle?: string;
    images: ImageProps[];
  };
}

export const InlineImage: FC<Props> = ({ sectionData: { title, subTitle, images } }) => (
  <>
    <InlineImageJsonLD sectionData={{ title, subTitle, images }} />
    <MediaSection
      title={title}
      subTitle={subTitle}
      medias={images.map(({ url, description }) => ({
        id: url,
        media: (
          <Image
            src={url}
            alt={description!}
            width={images.length === 1 ? 848 : 414}
            height={images.length === 1 ? 477 : 414}
            className={classNames(styles.image)}
          />
        ),
        description,
      }))}
    />
  </>
);
