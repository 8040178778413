import classNames from 'classnames';
import { isEmpty } from 'lodash';
import Link from 'next/link';
import { ComponentProps, FC, useCallback } from 'react';

import { CarouselSection } from 'src/general/components/Carousel/CarouselSection';
import { ConditionalWrapper } from 'src/general/components/ConditionalWrapper/ConditionalWrapper';
import { Image } from 'src/general/components/Image/Image';

import { FlexibleSection } from '../flexibleContent.type';
import styles from './ImagesCarousel.module.scss';

type TileProps = {
  image: string;
  title?: string;
  description?: string;
  url?: string;
};

interface Props extends FlexibleSection {
  sectionData: {
    title?: string;
    tiles: TileProps[];
  };
}

export const ImagesCarousel: FC<Props> = ({ sectionData }) => {
  return (
    <section className={styles.root}>
      {!isEmpty(sectionData.title) && (
        <header className={styles.header}>
          <h2 className="c-fs-h5">{sectionData.title}</h2>
        </header>
      )}
      <CarouselSection
        tiles={sectionData.tiles.map((tile, index) => ({
          id: `${tile.image}${index}`,
          tile: <Tile {...tile} />,
        }))}
      />
    </section>
  );
};

const Tile: FC<TileProps> = ({ image, title, description, url }) => {
  const conditionalWrapperLink: ComponentProps<typeof ConditionalWrapper>['wrapper'] = useCallback(
    (children) => (
      <Link href={url!} className={styles.link}>
        {children}
      </Link>
    ),
    [url],
  );

  return (
    <ConditionalWrapper condition={!isEmpty(url)} wrapper={conditionalWrapperLink}>
      <>
        <Image src={image} alt={title ?? ''} width={400} height={500} className={styles.image} />
        {(!isEmpty(title) || !isEmpty(description)) && (
          <div className={styles.content}>
            {!isEmpty(title) && <h3 className={classNames(styles.tileTitle, 'c-fs-h6')}>{title}</h3>}
            {!isEmpty(description) && <p className={styles.tileDescription}>{description}</p>}
          </div>
        )}
      </>
    </ConditionalWrapper>
  );
};
