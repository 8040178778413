import { FC, useMemo } from 'react';

import { RelatedArticles as RelatedArticlesComponent } from 'src/content-hub/components/RelatedArticles/RelatedArticles';
import { PageArticleDataNoContent } from 'src/data/Contentful/ContentfulGetArticle';
import { ContentfulObjectType } from 'src/data/Contentful/ContentfulTypes';

import { FlexibleSection } from '../flexibleContent.type';
import { getLinkedEntries } from '../getLinkedEntry';

interface Props extends FlexibleSection {
  sectionData: {
    sectionTitle: string;
    articles: string[];
  };
}

export const RelatedArticles: FC<Props> = ({ sectionData: { sectionTitle, articles }, linkedEntries }) => (
  <RelatedArticlesComponent
    title={sectionTitle}
    articles={useMemo(
      () => getLinkedEntries<PageArticleDataNoContent>(articles, ContentfulObjectType.PAGE_ARTICLE, linkedEntries),
      [articles, linkedEntries],
    )}
  />
);
