'use client';
import { ImageProps, getImageProps } from 'next/image';
import { FC } from 'react';
import { getCarmaGenericImageLoader } from 'src/general/helpers/carmaGenericImageLoader';
import breakpoints from 'src/theme/breakpoints.module.scss';

interface DesktopMobileImageProps extends Omit<ImageProps, 'src' | 'loader'> {
  /** Breakpoint at which to switch between desktop and mobile images. */
  switchBreakpoint?: 'sm' | 'md' | 'mdp' | 'lg' | 'xl';
  /** URL for desktop image. */
  desktopSrc: string;
  /** URL for mobile image. */
  mobileSrc: string;
}

/**
 * Next JS recomended way to display different images for mobile and desktop.
 * @see {@link https://nextjs.org/docs/app/api-reference/components/image#art-direction Next JS docs}
 */
export const DesktopMobileImage: FC<DesktopMobileImageProps> = ({
  switchBreakpoint = 'md',
  desktopSrc,
  mobileSrc,
  ...commonProps
}) => {
  const {
    props: { srcSet: desktop },
  } = getImageProps({ ...commonProps, src: desktopSrc, loader: getCarmaGenericImageLoader(desktopSrc) });
  const {
    props: { srcSet: mobile, alt, ...rest },
  } = getImageProps({ ...commonProps, src: mobileSrc, loader: getCarmaGenericImageLoader(mobileSrc) }) as {
    // Return type of getImageProps excludes 'alt' but it is included in the result.
    // As 'alt' is required for the jsx-a11y/alt-text rule we need to update the typing to make both Typescript & ESLint happy.
    props: ReturnType<typeof getImageProps>['props'] & { alt: string };
  };

  const breakpointPixels = breakpoints[`screen-${switchBreakpoint}`];
  const breakpointMediaQuery = `(min-width: ${breakpointPixels}px)`;

  return (
    <picture>
      <source media={breakpointMediaQuery} srcSet={desktop} />
      <source srcSet={mobile} />
      <img
        {...rest}
        // MDN recommends always including a 'src' attribute for an <img /> in a <picture>
        // for browsers that don't support the <picture> element.
        src={mobileSrc}
        alt={alt}
      />
    </picture>
  );
};
