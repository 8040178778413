import dynamic from 'next/dynamic';
import { ComponentProps, FC } from 'react';

import { FlexibleSection } from '../flexibleContent.type';

const SectionLayout = dynamic(() => import('../SectionLayout/SectionLayout').then((mod) => mod.SectionLayout));

interface FeatureSectionCTABase {
  label: string;
}

interface FeatureSectionCTALink extends FeatureSectionCTABase {
  buttonType: 'link';
  href: string;
}

interface FeatureSectionCTAModal extends FeatureSectionCTABase {
  buttonType: 'openModal';
  modalContent: Array<{
    subtext: string;
    subtitle: string;
  }>;
}

interface Props extends FlexibleSection {
  sectionData: {
    title: string;
    tagLabel?: string;
    tagIcon?: string;
    halfHeight: boolean;
    horizontalXS: boolean;
    buttonAligned: boolean;
    hideNumber: boolean;
    numbersIconColorChange: boolean;
    items: Array<{
      title: string;
      image: string;
      content: string;
      cta?: FeatureSectionCTALink | FeatureSectionCTAModal;
    }>;
  };
}

const featureSectionPropsToSectionLayoutProps = ({
  sectionData,
  linkedEntries,
}: Props): ComponentProps<typeof SectionLayout> => ({
  sectionData: {
    title: sectionData.title,
    tagLabel: sectionData.tagLabel,
    tagIcon: sectionData.tagIcon,
    isHorizontal: sectionData.horizontalXS,
    isHalfHeight: sectionData.halfHeight,
    isButtonsAligned: sectionData.buttonAligned,
    isHiddenNumber: sectionData.hideNumber,
    isNumbersColorChange: sectionData.numbersIconColorChange,
    items: sectionData.items.map((item) => ({
      title: item.title,
      content: item.content,
      image: item.image,
      // eslint-disable-next-line no-nested-ternary
      cta: item.cta
        ? item.cta.buttonType === 'link'
          ? {
              label: item.cta.label,
              type: item.cta.buttonType,
              href: item.cta.href,
            }
          : {
              label: item.cta.label,
              type: 'openModal',
              modal: {
                title: item.title,
                image: item.image,
                carouselContent: item.cta.modalContent.map(({ subtitle, subtext }) => ({
                  subTitle: subtitle,
                  subText: subtext,
                })),
              },
            }
        : undefined,
    })),
  },
  linkedEntries,
});

export const FeatureSection: FC<Props> = (data) => <SectionLayout {...featureSectionPropsToSectionLayoutProps(data)} />;
