import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { ComponentProps, FC } from 'react';
import ReactMarkdown from 'react-markdown';

import { CTAButton } from 'src/general/components/Button/CTAButton';
import { Image } from 'src/general/components/Image/Image';

import { FlexibleSection } from '../flexibleContent.type';
import styles from './MediaAndContent.module.scss';

interface Props extends FlexibleSection {
  sectionData: {
    /**
     * Direction in which the content is displayed:
     * - regular: Content on the left
     * - reverse: Content on the right
     */
    direction: 'regular' | 'reverse';
    background?: 'transparent' | 'white';
    sectionTitle?: string;
    title: string;
    subTitle?: string;
    content: string;
    media: {
      type: 'image';
      src: string;
    };
    cta?: ComponentProps<typeof CTAButton>[];
  };
}

export const MediaAndContent: FC<Props> = ({
  sectionData: { direction, background = 'transparent', sectionTitle, title, subTitle, content, media, cta },
}) => (
  <section className={classNames(styles.root, styles[background])}>
    {!isEmpty(sectionTitle) && (
      <header className={styles.header}>
        <h2 className={classNames('c-fw-bold', 'c-fs-h4')}>{sectionTitle}</h2>
      </header>
    )}
    <div
      className={classNames(styles.container, {
        [styles.reverse]: direction === 'reverse',
      })}
    >
      <div className={styles.contentWrapper}>
        <h3 className={classNames('c-fw-bold', 'c-fs-h4')}>{title}</h3>
        {!isEmpty(subTitle) && <p className={classNames(styles.subTitle, 'c-fs-h5', 'c-fw-normal')}>{subTitle}</p>}
        <div className={classNames(styles.content, 'c-fs-body1')}>
          <ReactMarkdown>{content}</ReactMarkdown>
        </div>
        {cta && !isEmpty(cta) && (
          <div className={styles.buttonContainer}>
            {cta.map((item) => (
              <CTAButton {...item} size="medium" key={item.label?.toString()} />
            ))}
          </div>
        )}
      </div>
      {media.type === 'image' && !isEmpty(media.src) && (
        <div className={styles.imgWrapper}>
          <Image
            className={styles.image}
            src={media.src}
            sizes="(max-width: 580px) 60vw, 30vw"
            alt=""
            width={520}
            height={520}
          />
        </div>
      )}
    </div>
  </section>
);
