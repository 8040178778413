'use client';

import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { useSnackbar } from 'notistack';
import { ComponentProps, FC } from 'react';

import { Image } from 'src/general/components/Image/Image';
import { FinanceEnquiryPayload, sendFinanceEnquiry } from 'src/services/LeadService';
import { pushToDataLayerWithEnhancedConversion } from 'src/utils/pushToDataLayer';

import { FinanceEnquiryFormValues } from '../../FinanceCalculator/FinanceCalculator.type';
import { FinanceDescriptionContent } from '../FinanceDescriptionContent/FinanceDescriptionContent';
import { FlexibleSection } from '../flexibleContent.type';
import styles from './FinanceHeroBanner.module.scss';

const FinanceHeroCalculator = dynamic(() =>
  import('../../FinanceCalculator/FinanceHeroCalculator/FinanceHeroCalculator').then(
    (mod) => mod.FinanceHeroCalculator,
  ),
);

interface Props extends FlexibleSection {
  sectionData: {
    title: string;
    subtitle: string;
    financeUSPs: {
      icon: string;
      description: string;
    }[];
    lenderIntro: string;
    lenders: {
      icon: string;
    }[];
    note: string;
    termOfLoan: {
      min: number;
      max: number;
    };
    interestRateRange: {
      min: number;
      max: number;
    };
    buttonLabel: string;
  };
  microcopy: ComponentProps<typeof FinanceHeroCalculator>['microcopy'] & {
    errorMessage: string;
  };
}

export const FinanceHeroBanner: FC<Props> = ({ sectionData, microcopy }) => {
  const { enqueueSnackbar } = useSnackbar();

  const onSubmitEnquiry = async (data: FinanceEnquiryFormValues) => {
    const payload: FinanceEnquiryPayload = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.emailAddress,
      phone: data.phoneNumber,
      message: data.message,
      deposit_amount: data.depositAmount,
      interest_rate: data.interestRate,
      purchase_price: data.purchasePrice,
      term_of_loan: data.termOfLoan,
      weekly_payment: data.weeklyPayment,
    };
    const response = await sendFinanceEnquiry(payload);
    pushToDataLayerWithEnhancedConversion(data.emailAddress, data.phoneNumber, {
      event: 'finance_enquiry_form_submit',
      value: payload,
    });

    // handle error
    if (response?.status === 400) {
      const message = microcopy.errorMessage;
      enqueueSnackbar(message, { variant: 'error' });
      return message;
    }

    enqueueSnackbar('Your enquiry has been sent successfully', { variant: 'success' });

    return true;
  };

  return (
    <section className={styles.root}>
      <div className={styles.contentWrapper}>
        <div className={styles.topWell}>
          <FinanceDescriptionContent
            sectionData={{
              title: sectionData.title,
              subtitle: sectionData.subtitle,
              financeUSPs: sectionData.financeUSPs,
              lenderIntro: sectionData.lenderIntro,
              lenders: sectionData.lenders,
            }}
            inPDP={false}
          />
          <div className={classNames(styles.calWrapper, styles.desktopOnly)}>
            <FinanceHeroCalculator
              button={{
                label: sectionData.buttonLabel,
                buttonType: 'button',
                variant: 'secondary',
                size: 'small',
              }}
              interestRateRange={{
                minRate: sectionData.interestRateRange.min,
                maxRate: sectionData.interestRateRange.max,
              }}
              loanTerms={sectionData.termOfLoan}
              onSubmit={onSubmitEnquiry}
              microcopy={microcopy}
            />
          </div>
          <div className={styles.lineWrapper} />
          <div className={styles.carWrapper}>
            <Image
              className={styles.carImage}
              src="https://img.carma.com.au/ui-assets/FinanceHeroBanner/finance-car.png"
              width={1068}
              height={433}
              alt=""
            />
          </div>
        </div>
        <div className={styles.bottomWell}>
          <div className={classNames(styles.calWrapper, styles.mobileOnly)}>
            <FinanceHeroCalculator
              button={{
                label: sectionData.buttonLabel,
                buttonType: 'button',
                variant: 'secondary',
                size: 'small',
              }}
              interestRateRange={{
                minRate: sectionData.interestRateRange.min,
                maxRate: sectionData.interestRateRange.max,
              }}
              loanTerms={sectionData.termOfLoan}
              onSubmit={onSubmitEnquiry}
              microcopy={microcopy}
            />
          </div>
          <div className={styles.noteWrapper}>
            <p className={styles.note}>{sectionData.note}</p>
          </div>
        </div>
      </div>
    </section>
  );
};
