import Link from 'next/link';
import { FC } from 'react';

import { FlexibleSection } from '../flexibleContent.type';
import styles from './LinkBlock.module.scss';
import classNames from 'classnames';

interface LinkBlockProps extends FlexibleSection {
  sectionData: {
    title?: string;
    linkNames: Array<{
      name: string;
      href: string;
    }>;
  };
}

export const LinkBlock: FC<LinkBlockProps> = ({ sectionData: { title, linkNames } }) => (
  <section className={styles.root}>
    <h4 className={classNames(styles.title, 'c-fw-bold', 'c-fs-h4')}>{title}</h4>
    <div className={styles.container}>
      {linkNames.map((item) => (
        <Link key={item.name} href={item.href} className={classNames(styles.link, 'c-fw-normal', 'c-fs-h6')}>
          {item.name}
        </Link>
      ))}
    </div>
  </section>
);
