'use client';

import classNames from 'classnames';
import { usePathname, useRouter } from 'next/navigation';
import { SnackbarKey, useSnackbar } from 'notistack';
import qs from 'query-string';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import shallow from 'zustand/shallow';

import { addWatchlistItem, removeWatchlistItem } from 'src/data/WatchListApi/WatchlistApi';
import { IconHeart } from 'src/general/Icons/IconHeart';
import { watchlistStore } from 'src/stores/watchListStore';
import { ProductListDataRow } from 'src/types/CataloguePage.types';
import { DataLayerWatchlistEvent, generateSourceString, pushToDataLayerWatchList } from 'src/utils/pushToDataLayer';

import { authStore } from '../../../stores/authStore';
import { Button } from '../Button/Button';
import styles from './AddToWatchList.module.scss';

export interface WatchListItem {
  sku: string;
  product?: Partial<ProductListDataRow>;
  createdAt: string;
}

export const enum AddToWatchListStyle {
  button = 'button',
  icon = 'icon',
}

type LabelsOverwrite = {
  save: ReactNode;
  saved: ReactNode;
};

interface AddToWatchListProps {
  sku: string;
  product?: Partial<ProductListDataRow>;
  style?: AddToWatchListStyle;
  className?: string;
  labelsOverwrite?: LabelsOverwrite;
  disableSnackbar?: boolean;
  onWatchlistStatusChange?: () => Promise<void>;
}

const addToWatchlistMessage = 'Vehicle has been added to your watchlist.';
const removeFromWatchlistMessage = 'Vehicle has been removed from your watchlist.';

export const AddToWatchList: FC<AddToWatchListProps> = ({
  sku,
  product = {},
  style = AddToWatchListStyle.icon,
  className,
  labelsOverwrite,
  onWatchlistStatusChange,
  disableSnackbar = false,
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const { isUserLoggedIn } = authStore(
    (state) => ({
      isUserLoggedIn: state.isUserLoggedIn,
    }),
    shallow,
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { hasWatchlistItem, setWatchlistData } = watchlistStore((state) => ({
    hasWatchlistItem: state.hasWatchlistItem,
    setWatchlistData: state.setWatchlistData,
  }));
  const [optimisticallyAdded, setOptimisticallyAdded] = useState<boolean | undefined>(undefined);

  const userAddedItem = useMemo(
    () => optimisticallyAdded ?? hasWatchlistItem(sku),
    [optimisticallyAdded, sku, hasWatchlistItem(sku)],
  );

  const [isIcon, isButton] = useMemo(
    () => [style === AddToWatchListStyle.icon, style === AddToWatchListStyle.button],
    [style],
  );

  const handleAddWatchlistItem = useCallback(() => {
    setOptimisticallyAdded(true);
    if (!disableSnackbar) {
      enqueueSnackbar(addToWatchlistMessage, { variant: 'success' });
    }
    addWatchlistItem({ sku }).then((response) => {
      setWatchlistData(response ?? null);
      if (!disableSnackbar) {
        enqueueSnackbar(addToWatchlistMessage, {
          variant: 'success',
        });
      }
      if (onWatchlistStatusChange) {
        onWatchlistStatusChange();
      }
    });
  }, [sku]);

  const undoButtonAction = (key: SnackbarKey) => (
    <button
      type="button"
      className={styles.watchlistundobutton}
      onClick={() => {
        handleAddWatchlistItem();
        pushToDataLayerWatchList(DataLayerWatchlistEvent.watchlist_undos, { sku });
        closeSnackbar(key);
      }}
    >
      Undo
    </button>
  );

  const handleRemoveWatchlistItem = useCallback(() => {
    setOptimisticallyAdded(false);
    if (!disableSnackbar) {
      enqueueSnackbar(removeFromWatchlistMessage, {
        action: undoButtonAction,
        variant: 'info',
      });
    }
    removeWatchlistItem(sku).then((response) => {
      setWatchlistData(response ?? null);
      if (!disableSnackbar) {
        enqueueSnackbar('Vehicle has been removed from your watchlist.', {
          variant: 'success',
        });
      }
      if (onWatchlistStatusChange) {
        onWatchlistStatusChange();
      }
    });
  }, [sku, handleAddWatchlistItem]);

  const handleClick = useCallback(() => {
    if (!isUserLoggedIn) {
      pushToDataLayerWatchList(DataLayerWatchlistEvent.watchlist_add_attempt, { sku });
      const redirectUrl = new URL(window.location.toString());
      redirectUrl.searchParams.append('watchlistSku', sku);
      const queryParams = qs.stringify({
        type: 'watchlist',
        sku,
        source: generateSourceString(pathname ?? ''),
        redirect: redirectUrl.toString(),
        ...product,
      });
      router.push(`/signin?${queryParams}`);
    } else if (userAddedItem) {
      handleRemoveWatchlistItem();
      pushToDataLayerWatchList(DataLayerWatchlistEvent.watchlist_remove, { sku });
    } else {
      handleAddWatchlistItem();
      pushToDataLayerWatchList(DataLayerWatchlistEvent.watchlist_successful_add, { sku, product });
    }
  }, [isUserLoggedIn, userAddedItem, handleAddWatchlistItem, handleRemoveWatchlistItem]);

  const buttonLabel = getButtonLabel(userAddedItem, labelsOverwrite);

  if (isButton) {
    return (
      <Button
        onClick={handleClick}
        size="small"
        data-testid={`AddToWatchList-component-button-${userAddedItem ? 'Saved' : 'Save'}`}
        startIcon={<IconHeart className="watchlistButton-icon" />}
        className={classNames('watchlistButton', className, {
          [styles.watchlistfilled]: userAddedItem,
          watchlistfilled: userAddedItem,
        })}
      >
        {buttonLabel}
      </Button>
    );
  }

  return (
    <button
      type="button"
      className={classNames(styles.watchlist, 'watchlistButton', className, {
        [styles.watchlistIcon]: isIcon,
        [styles.watchlistfilled]: userAddedItem,
        watchlistfilled: userAddedItem,
      })}
      onClick={handleClick}
      data-testid={`AddToWatchList-component-button-${userAddedItem ? 'Saved' : 'Save'}`}
      title="Add to watchlist"
    >
      <IconHeart className="watchlistButton-icon" />
    </button>
  );
};

const getButtonLabel = (userAddedItem: boolean, labelsOverwrite?: LabelsOverwrite) => {
  if (userAddedItem) {
    return labelsOverwrite ? labelsOverwrite.saved : 'Saved';
  }

  return labelsOverwrite ? labelsOverwrite.save : 'Save';
};
