import classNames from 'classnames';
import { ComponentProps, FC } from 'react';
import ReactMarkdown from 'react-markdown';

import { CTAButton } from 'src/general/components/Button/CTAButton';
import { Image } from 'src/general/components/Image/Image';
import { PromoPill } from 'src/general/components/PromoPill/PromoPill';

import { FlexibleSection } from '../flexibleContent.type';
import styles from './Marketing.module.scss';

interface Props extends FlexibleSection {
  sectionData: {
    marketingTiles: {
      tagLabel?: string;
      tagIcon?: string;
      title: string;
      subTitle?: string;
      content: string;
      cta: ComponentProps<typeof CTAButton>;
      image: string;
      hideTagLabel?: boolean;
    }[];
    backgroundVariant: 'blue' | 'white' | 'purple';
  };
}

export const Marketing: FC<Props> = ({ sectionData: { marketingTiles, backgroundVariant } }) => (
  <section className={styles.root}>
    <div className={classNames(styles.container, { [styles.container2Tiles]: marketingTiles.length === 2 })}>
      {marketingTiles.map(({ tagLabel, tagIcon, title, content, cta, image, subTitle, hideTagLabel }) => (
        <div className={styles.tileContainer} key={title}>
          <article
            className={classNames(styles.tile, {
              [styles.whiteTile]: backgroundVariant === 'white',
              [styles.purpleTile]: backgroundVariant === 'purple',
            })}
          >
            <div className={styles.tileImage}>
              <Image src={image} width={470} height={280} alt={title} />
            </div>
            {!hideTagLabel && tagLabel && (
              <div className={styles.tagLabelContainer}>
                <PromoPill
                  promoPillData={[
                    {
                      key: tagLabel,
                      copy: tagLabel,
                      backgroundColor: '#EBF2F5',
                      color: 'rgba(21, 13, 44, 0.88)',
                      icon: tagIcon,
                    },
                  ]}
                  className={classNames('c-fw-ultrabold', 'c-fs-overline', styles.tagLabel)}
                />
              </div>
            )}
            <header className={classNames(styles.tileHeader, styles.tileContentContainer)}>
              <h5 className={classNames('c-fw-bold', 'c-fs-h5', styles.tileTitle)}>{title}</h5>
            </header>
            {subTitle && <h6 className={classNames('c-fw-normal', 'c-fs-h6', styles.tileSubtitle)}>{subTitle}</h6>}
            <ReactMarkdown
              className={classNames('c-fw-normal', 'c-fs-body1', styles.tileContent, styles.tileContentContainer)}
            >
              {content}
            </ReactMarkdown>
            <footer className={classNames(styles.tileFooter, styles.tileContentContainer)}>
              <CTAButton {...cta} />
            </footer>
          </article>
        </div>
      ))}
    </div>
  </section>
);
