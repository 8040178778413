import classNames from 'classnames';
import { FC } from 'react';

import { Image } from 'src/general/components/Image/Image';
import { FinanceLogoIcon } from 'src/hygiene/templates/FinanceLogoIcon/FinanceLogoIcon';

import styles from './FinanceDescriptionContent.module.scss';

type FinanceDescriptionContentProps = {
  sectionData: {
    title: string;
    subtitle: string;
    financeUSPs: {
      icon: string;
      description: string;
    }[];
    lenderIntro: string;
    lenders: {
      icon: string;
    }[];
  };
  inPDP: boolean;
};

export const FinanceDescriptionContent: FC<FinanceDescriptionContentProps> = ({ sectionData, inPDP }) => {
  return (
    <div className={styles.content}>
      <div className={styles.headerWrapper}>
        <h1 className={classNames(styles.title, 'c-fw-bold', 'c-fs-h4')}>{sectionData.title}</h1>
        {sectionData.subtitle && (
          <p className={classNames(styles.subTitle, 'c-fw-normal', 'c-fs-body2')}>{sectionData.subtitle}</p>
        )}
      </div>
      <div className={styles.uspWrapper}>
        {sectionData.financeUSPs.map((item) => (
          <div className={styles.uspGroup} key={item.icon}>
            <Image src={item.icon} alt={item.description} width={40} height={40} />
            <span className={classNames('c-fw-normal', 'c-fs-body2')}>{item.description}</span>
          </div>
        ))}
      </div>
      <div className={styles.lenderWrapper}>
        <div className={styles.lenderContent}>
          <span className={styles.lenderIntro}>
            <p className={classNames(styles.intro, 'c-fw-normal', 'c-fs-body2')}>{sectionData.lenderIntro}</p>
          </span>
          <div
            className={classNames(styles.verticalDivider, {
              [styles.verticalDividerInPDP]: inPDP,
            })}
          />
          <div className={styles.lenderLogoWrapper}>
            {sectionData.lenders.map((item) => (
              <div key={item.icon} className={styles.lenderLogo}>
                <FinanceLogoIcon src={item.icon} alt={item.icon} width={92} height={32} />
              </div>
            ))}
          </div>
        </div>
        {inPDP && (
          <div className={styles.carWrapper}>
            <Image
              className={styles.carImage}
              src="https://img.carma.com.au/ui-assets/FinanceHeroBanner/finance-car.png"
              width={1068}
              height={433}
              alt=""
              sizes="(max-width: 580px) 174px, (max-width: 960px) 402px, (max-width: 1440px) 457px, 565px"
            />
          </div>
        )}
      </div>
    </div>
  );
};
