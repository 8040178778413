import classNames from 'classnames';
import Image from 'next/image';
import { ComponentProps, FC } from 'react';

import { CTAButton } from 'src/general/components/Button/CTAButton';
import { carmaGenericImageLoader } from 'src/general/helpers/carmaGenericImageLoader';

import { FlexibleSection } from '../flexibleContent.type';
import styles from './HeroHeader.module.scss';

interface Props extends FlexibleSection {
  sectionData: {
    preHeader?: string;
    title: string;
    subtitle?: string;
    textStyle: 'light' | 'dark';
    mobileBackgroundImgUrl: string;
    desktopBackgroundImgUrl: string;
    cta: ComponentProps<typeof CTAButton>[];
  };
}

export const HeroHeader: FC<Props> = ({ sectionData }) => (
  <section className={styles.root}>
    <div className={classNames(styles.image, styles.imageMobile)}>
      <Image
        src={sectionData.mobileBackgroundImgUrl}
        sizes="60vw"
        loader={carmaGenericImageLoader}
        layout="fill"
        priority
        alt=""
      />
    </div>
    <div className={classNames(styles.image, styles.imageDesktop)}>
      <Image
        src={sectionData.desktopBackgroundImgUrl}
        sizes="100vw"
        loader={carmaGenericImageLoader}
        layout="fill"
        priority
        alt=""
      />
    </div>
    <div className={classNames(styles.contentWrapper, styles[sectionData.textStyle])}>
      {sectionData.preHeader && <p className={styles.preHeader}>{sectionData.preHeader}</p>}
      <h1 className={styles.title}>{sectionData.title}</h1>
      {sectionData.subtitle && <div className={styles.subTitle}>{sectionData.subtitle}</div>}
      <div className={styles.buttonContainer}>
        {sectionData.cta.map((item) => (
          <CTAButton key={item.label?.toString()} {...item} />
        ))}
      </div>
    </div>
  </section>
);
