import classNames from 'classnames';
import Image from 'next/image';
import { ComponentProps, FC } from 'react';

import { CTAButton } from 'src/general/components/Button/CTAButton';

import { FlexibleSection } from '../flexibleContent.type';
import styles from './Awards.module.scss';

type Icon = {
  iconUrl: string;
};
interface Props extends FlexibleSection {
  sectionData: {
    icons: Icon[];
    title: string;
    description: string;
    cta?: ComponentProps<typeof CTAButton>[];
  };
  className?: string;
}

export const Awards: FC<Props> = ({ sectionData: { description, title, icons, cta }, className }) => (
  <section className={classNames(styles.root, className)}>
    <header className={styles.headerContainer}>
      <h4 className={classNames('c-fw-bold', 'c-fs-h6', styles.title)}>{title}</h4>
    </header>
    <div className={classNames('c-fw-normal', 'c-fs-body2', styles.description)}>{description}</div>
    <div className={styles.awardsContainer}>
      {icons.map(({ iconUrl }) => (
        <div className={styles.imageContainer} key={iconUrl}>
          <Image
            className={styles.image}
            src={iconUrl}
            sizes="(max-width: 580px) 7vw, 5vw"
            width={72}
            height={72}
            alt={iconUrl}
          />
        </div>
      ))}
    </div>
    {cta && (
      <div className={styles.buttonContainer}>
        {cta.map((item) => (
          <CTAButton size="small" key={item.label?.toString()} {...item} />
        ))}
      </div>
    )}
  </section>
);
