'use client';

import classNames from 'classnames';
import { noop } from 'lodash';
import {
  FC,
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';

import styles from './Tabs.module.scss';

interface Props {
  className?: string;
  tabStyle?: string;
  tabStyleActive?: string;
  children: ReactNode;
}

export const Tabs: FC<Props> = ({ className, tabStyle, tabStyleActive, children }) => {
  const [tabs, setTabs] = useState<Record<string, { label: ReactNode; testId?: string }>>({});
  const [activeTab, setActiveTab] = useState('');
  const [offsetLeft, setOffsetLeft] = useState<null | number>(null);

  const registerTab = useCallback((tabId: string, label: ReactNode, testId?: string) => {
    let isFirstTab = false;

    setTabs((tabs) => {
      if (!Object.keys(tabs).length) {
        isFirstTab = true;
      }

      return { ...tabs, [tabId]: { label, testId } };
    });

    // If this is the first tab registered, set it as the active tab.
    if (isFirstTab) {
      setActiveTab(tabId);
    }

    return () =>
      setTabs((tabs) => {
        const newTabs = { ...tabs };
        delete newTabs[tabId];
        return newTabs;
      });
  }, []);

  const tabsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeTab) {
      const e = tabsRef.current?.children.namedItem(getTabLabelId(activeTab)) as HTMLElement;
      setOffsetLeft(e.offsetLeft);
    }
  }, [activeTab]);

  const value: TabContextValue = useMemo(() => ({ activeTab, registerTab }), [activeTab, registerTab]);

  return (
    <div className={classNames(styles.root, className)}>
      <TabContext.Provider value={value}>
        <div ref={tabsRef} className={styles.tabs} role="tablist">
          {Object.entries(tabs).map(([tabId, { label, testId }]) => (
            <TabHeader
              key={tabId}
              tabId={tabId}
              active={tabId === activeTab}
              setActive={setActiveTab}
              className={tabStyle}
              classNameActive={tabStyleActive}
              testId={testId}
            >
              {label}
            </TabHeader>
          ))}
          {offsetLeft !== null && (
            <span
              className={styles.activeIndicator}
              style={{
                transform: `translate3d(${offsetLeft}px, 0, 0)`,
                width: `calc(100% / ${Object.keys(tabs).length})`,
              }}
            />
          )}
        </div>
        {children}
      </TabContext.Provider>
    </div>
  );
};

interface TabHeaderProps {
  tabId: string;
  active: boolean;
  setActive: (tabId: string) => void;
  className?: string;
  classNameActive?: string;
  testId?: string;
  children: ReactNode;
}

const TabHeader = memo<TabHeaderProps>(function TabHeader({
  tabId,
  active,
  setActive,
  className,
  classNameActive,
  testId,
  children,
}) {
  return (
    <button
      id={getTabLabelId(tabId)}
      type="button"
      role="tab"
      aria-selected={active}
      aria-controls={tabId}
      className={classNames(styles.tabHeader, className, {
        [styles.active]: active,
        [classNameActive!]: classNameActive && active,
      })}
      onClick={() => setActive(tabId)}
      data-testid={testId}
    >
      {children}
    </button>
  );
});

interface TabProps {
  label: ReactNode;
  testId?: string;
  tabHeaderTestId?: string;
  className?: string;
  children: ReactNode;
}

export const Tab: FC<TabProps> = ({ label, testId, tabHeaderTestId, className, children }) => {
  const tabId = useId();
  const { activeTab, registerTab } = useContext(TabContext);
  useEffect(() => registerTab(tabId, label, tabHeaderTestId), [registerTab, tabId, label]);

  return (
    <div
      id={tabId}
      role="tabpanel"
      data-testid={testId}
      aria-labelledby={getTabLabelId(tabId)}
      className={classNames(styles.tab, className, { [styles.hidden]: activeTab !== tabId })}
    >
      {children}
    </div>
  );
};

interface TabContextValue {
  activeTab: string;
  registerTab: (tabId: string, label: ReactNode, testId?: string) => () => void;
}
const tabContextInitialValue: TabContextValue = { activeTab: '', registerTab: () => noop };
export const TabContext = createContext<TabContextValue>(tabContextInitialValue);

const getTabLabelId = (tabId: string) => `tab-${tabId}`;
