'use client';

import { FC } from 'react';
import shallow from 'zustand/shallow';

import { CAR_PURCHASE_STATUS } from 'src/constants';
import { authStore } from 'src/stores/authStore';
import { cartStore } from 'src/stores/cartStore';

import { useUpdatedCarStatus } from '../Results/useUpdatedCarStatus';
import { PillPurchaseStatuses } from './StatusPill.type';
import { StatusPillContent } from './StatusPillContent';

interface Props {
  sku: string;
  state: CAR_PURCHASE_STATUS;
}

export const StatusPill: FC<Props> = ({ sku, state }) => {
  const carStatus = useUpdatedCarStatus(sku, state);

  const { userOrders } = authStore(
    (state) => ({
      userOrders: state.userOrders,
    }),
    shallow,
  );

  const { carData, cartExpired } = cartStore(
    (state) => ({
      carData: state.carData,
      cartExpired: state.cartExpired,
    }),
    shallow,
  );

  let status: PillPurchaseStatuses | 'myPurchase' | undefined = undefined;

  if (carStatus === 'draftOrder') {
    status = 'draftOrder';
  } else if (carStatus === 'reserved' || carStatus === 'purchased' || carStatus === 'delivered') {
    status = 'reserved';
  }

  if ((carData?.sku === sku && !cartExpired) || userOrders.find((item) => item.vehicle.sku === sku)) {
    status = 'myPurchase';
  }

  return <StatusPillContent status={status} />;
};
