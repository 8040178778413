import { FC } from 'react';
import { TradeInSearchCar } from 'src/hygiene/components/TradeInEnquiry/TradeInSearchCar/TradeInSearchCar';
import { FlexibleSection } from '../flexibleContent.type';
import { DesktopMobileImage } from './DesktopMobileImage';
import styles from './HeroTradeIn.module.scss';

interface Props extends FlexibleSection {
  sectionData: {
    title: string;
    mobileBackgroundImgUrl: string;
    desktopBackgroundImgUrl: string;
  };
}

export const HeroTradeIn: FC<Props> = ({ sectionData }) => (
  <section className={styles.root}>
    <DesktopMobileImage
      className={styles.backgroundImage}
      desktopSrc={sectionData.desktopBackgroundImgUrl}
      mobileSrc={sectionData.mobileBackgroundImgUrl}
      // Placeholder solid colour 1x1 image
      placeholder="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPcUhH1HwAF9QKHddhrHQAAAABJRU5ErkJggg=="
      priority
      fill
      alt=""
    />
    <div className={styles.content}>
      <header className={styles.header}>
        <h1 data-testid="TRADE_IN_BANNER_TITLE" className={styles.title}>
          {sectionData.title}
        </h1>
      </header>
      <div className={styles.form}>
        <TradeInSearchCar />
      </div>
    </div>
  </section>
);
