import classNames from 'classnames';
import { ComponentProps, FC } from 'react';

import { CTAButton } from 'src/general/components/Button/CTAButton';
import { Image } from 'src/general/components/Image/Image';

import styles from './PlpMarketingTile.module.scss';

type Props = Partial<{
  title: string;
  description?: string;
  image: string;
  cta: ComponentProps<typeof CTAButton>[];
}> & { variant: 'plp' | 'carousel' };

export const PlpMarketingTile: FC<Props> = ({ title, description, image, cta, variant }) => {
  const hasImage = !!image;
  const hasDescription = !!description;
  const hasCta = !!cta?.length;

  return (
    // Needs to be a `li` to be used in the PLP
    <li className={classNames(styles.root, { [styles.noCta]: !hasCta, [styles.carouselTile]: variant === 'carousel' })}>
      <div className={styles.imageContainer}>
        <div className={styles.imageWrapper}>
          {hasImage && <Image src={image} alt="" width={200} height={200} className={styles.image} />}
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.title}>{title}</div>
        {hasDescription && <div className={styles.description}>{description}</div>}
      </div>
      {hasCta && (
        <div className={classNames(styles.ctaContainer, { [styles.carouselSpacing]: variant === 'carousel' })}>
          <CTAButton {...cta[0]} size="small" className={styles.cta} />
        </div>
      )}
    </li>
  );
};
