import { identity, isPlainObject, mapValues } from 'lodash';
import { FC, ReactNode } from 'react';

import { IconCarmaLogo } from 'src/general/Icons/IconCarmaLogo';

/**
 * A token is a special string that can be included in text from contentful, but will be replaced with a component in
 * the UI.
 * Typically this will be an image/icon.
 */
export enum Token {
  /** The carma logo as an icon. */
  CARMA = 'Carma',
}

/**
 * Takes a given string and returns a ReactNode array.
 * Tokens have been replaced with their corresponding component,
 * all other parts of the string are left unchanged
 *
 * @example
 * parseTokens('foo{Carma}bar') => ['foo', <svg>...</svg>, 'bar']
 */
export const parseTokens = (str: string): ReactNode[] =>
  str
    // Split on tokens. Captured tokens are spliced into the output array.
    .split(/({.*?})/)
    // Remove empty strings.
    .filter(identity)
    .map((strPart) => {
      // Extract the token from the string.
      // Attempt to match string as a token, capturing the token's name.
      const matches = /{(.*?)}/.exec(strPart);

      // If matches if falsy, this isn't a token string. Return unchanged.
      if (!matches) {
        return strPart;
      }

      // Extract the captured token name.
      const [_, token] = matches;

      // Return the corresponding component for the given token, or null if no corresponding component exists.
      return <TokenComponent key={strPart} token={token as Token} />;
    });

/**
 * Component that maps each token to the component that will replace it.
 */
export const TokenComponent: FC<{ token: Token }> = ({ token }) => {
  switch (token) {
    case Token.CARMA:
      return <IconCarmaLogo />;
    default:
      return null;
  }
};

/**
 * Helper type, representing the return type of the 'parseTokensRecursive' function.
 */
export type ParsedTokens<T> =
  T extends Array<infer V>
    ? Array<ParsedTokens<V>>
    : T extends object
      ? { [K in keyof T]: ParsedTokens<T[K]> }
      : T extends string
        ? ReactNode
        : T;

/**
 * Parses strings containing tokens in a given value.
 * Recurses on array items and object properties.
 * Any other types of values are left unchanged.
 *
 * @see {@link Token}
 */
export const parseTokensRecursive = <T extends any>(value: T): ParsedTokens<T> => {
  if (Array.isArray(value)) {
    return value.map(parseTokensRecursive) as any;
  } else if (isPlainObject(value)) {
    return mapValues(value as any, parseTokensRecursive) as any;
  } else if (typeof value === 'string') {
    const parsedTokens = parseTokens(value);
    return (parsedTokens.length === 1 ? parsedTokens[0] : parsedTokens) as any;
  } else {
    return value as any;
  }
};
