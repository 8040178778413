import { ContentfulSectionFlexibleSection } from 'src/data/Contentful/ContentfulTypes';

type LinkedEntry = ContentfulSectionFlexibleSection['linkedEntriesCollection']['items'][0];

/**
 * Look through the entries to find the entry with the right id and type
 */
export function getLinkedEntry<T extends LinkedEntry>(
  id: string,
  objectType: T['__typename'],
  linkedEntries?: LinkedEntry[],
): T | null {
  return (linkedEntries?.find((item) => item.sys.id === id && item.__typename === objectType) as T) ?? null;
}

/**
 * Look through the entries to find the entries with the right ids and type
 */
export function getLinkedEntries<T extends LinkedEntry>(
  ids: string[],
  objectType: T['__typename'],
  linkedEntries?: LinkedEntry[],
): T[] {
  return ids.map((id) => getLinkedEntry(id, objectType, linkedEntries)).filter((item) => item === null) as T[];
}
